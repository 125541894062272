import React from 'react'
import Tiles from '../../../react4xp/components/Tiles'
import Card from '../../../react4xp/components/Card'

function ChildrenListPart({ error, items, filters, numberOfItems }) {
  if (error) {
    return <div>{error}</div>
  }
  return (
    <Tiles
      maxCards={numberOfItems}
      filters={filters}
      cards={items && items.map(item => (
        <Card
          key={item.path}
          buttonText={item.readMoreText}
          href={item.href}
          isExternalUrl={item.isExternalUrl}
          imgSrc={item.imageSrc?.link}
          imgSrcMedium={item.imageSrcMedium?.link}
          imgAlt={item.imageSrc?.alt}
          imgWidth={960}
          title={item.name}
          ingress={item.intro}
          type={item.filterName}
          isChildrenListCard
        />
      ))}
    />
  )
}

export default (props) => <ChildrenListPart {...props} />
